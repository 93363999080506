<template>
  <div style="background-color: #fff; padding: 10px 5px; min-height: 100%">
    <el-table
      v-loading="loading"
      :data="campaignTableInfo"
      :header-cell-style="headerStyle"
      :cell-style="cellStyle"
      stripe
    >
      <el-table-column align="center" type="index" label="序号" width="50" />
      <el-table-column align="center" prop="campaignName" label="活动名称">
        <template #default="scope">
          <p
            style="cursor: pointer; color: #51a6f0"
            @click="intoDetail(scope.row)"
          >
            {{ scope.row.campaignName }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in colData"
        :key="item.prop"
        :width="item.width"
        :align="item.align"
        :prop="item.prop"
        :label="item.label"
      />
      <el-table-column label="活动时间" align="center" prop="duration">
        <template #default="scope">
          {{ scope.row.duration.replace(",", " 至 ") }}
        </template>
      </el-table-column>
      <el-table-column label="活动状态" align="center" prop="campaignStatus">
        <template #default="scope">
          {{ campaignStatusZh(scope.row.campaignStatus) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template #default="scope">
          <el-tooltip content="详情" placement="top">
            <el-button size="mini" type="text" @click="intoDetail(scope.row)">
              <svg-icon class-name="iconlvzhou_gengduo_xiangqing" />
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin-top: 20px; padding-bottom: 10px">
      <el-pagination
        background
        layout="prev, pager, next, total"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/quote";

export default {
  name: "quote",
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      colData: [
        {
          width: null,
          align: "center",
          prop: "brand",
          label: "活动品牌",
        },
        {
          width: null,
          align: "center",
          prop: "campaignDesc",
          label: "活动说明",
        },
      ],
      campaignTableInfo: [],
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
    };
  },
  mounted() {
    this.getCampaignList();
  },
  methods: {
    async getCampaignList() {
      this.loading = true;
      let params = { page: this.page, size: this.pageSize };
      const {
        data: { agencyQuoteList, total },
      } = await getList(params);
      this.total = total;
      this.campaignTableInfo = agencyQuoteList;
      this.loading = false;
    },
    intoDetail({ campaignId, agencyId, campaignStatus, campaignName }) {
      this.$storage.setItem("activeName", campaignName);
      const query = {
        campaignId: this.btoa(campaignId),
        agencyId: this.btoa(agencyId),
        campaignStatus: this.btoa(campaignStatus),
      };
      this.$router.push({ path: "quoteDetail", query });
    },
    campaignStatusZh(status) {
      switch (status) {
        case "1":
          return "等待报价";
        case "2_1":
          return "报价中";
        case "3":
          return "报价完成等待审批";
        case "4":
          return "审批未通过";
        case "5":
          return "审批通过等待执行";
        case "6":
          return "活动进行中";
        case "7":
          return "活动完成";
        case "8":
          return "活动取消";
      }
    },
    handlePage(page) {
      this.page = page;
      this.getCampaignList();
    },
  },
};
</script>

<style scoped lang="scss">
.load_button {
  text-align: center;
  color: #aaa;
  height: 40px;
  line-height: 40px;
}
</style>
